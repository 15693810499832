<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="
            container
            shape-container
            d-flex
            align-items-center
            text-center-sm
          "
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  Terms & Conditons
                </h1>
                <p class="f-16 text-dark mb-0">
                  Welcome to Imperium website (the "Site").
                </p>
              </div>
              <div class="col-md-7">
                <div class="text-center">
                  <img
                    class="headerimg"
                    src="/assets/images/svgs/faqimg.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div id="terms-and-conditions">
              <section class="introduction">
                <header><h3>1. Introduction</h3></header>
                <article class="details">
                  <p>
                    Welcome to Imperium website (the "Site"). The Site is owned
                    and operated by Sterling Bank Plc. through its Non-Interest
                    Banking window, Sterling Alternative Finance (“We”, “Our”,
                    “Us”). These "Terms and Conditions” apply to the Site, and
                    all its internet operations. The Cost-plus contract is
                    utilized for purchases made on this site.
                  </p>
                  <p>
                    These Terms and Conditions govern and apply to the grant of
                    access to use the Site and the purchase of any products
                    (“Services”) on the Site by a User ("You", "Your" “They”
                    “Their”). By purchasing, accepting, or subscribing to any of
                    the Service offerings on the Site, you agree to be bound by
                    these terms and conditions on behalf of yourself and others
                    who purchase, accept, or subscribe under your account. By
                    clicking “I Agree”, you give consent to be bound by the
                    terms herein. Do not use our services, if you do not agree
                    to the terms and conditions stated herein. Kindly review the
                    terms and conditions listed below diligently prior to using
                    this Site as your use of the Site indicates your agreement
                    to be wholly bound by the Terms and Conditions without
                    modification.
                  </p>

                  <p>
                    You agree that if you are unsure of the meaning of any part
                    of or have any question regarding the Terms and Conditions
                    you will not hesitate to contact us for clarification. These
                    Terms and Conditions fully govern the use of this website.
                    No extrinsic meaning or interpretation, whether oral or
                    written, will be incorporated.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>2. ABOUT IMPERIUM</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    Imperium is a digital marketplace where we facilitate
                    renewable energy transactions by bringing buyers and sellers
                    to converge at one point. We onboard sellers (vendors) and
                    they list their products on the platform. We onboard buyers;
                    they buy their products on the platform.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>3. MODIFICATION OF TERMS</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    We reserve the right to change, modify, add, or remove
                    portions of this Terms and Conditions at any time. Changes
                    will be effective when posted on the Site with no other
                    notice provided. Please check these Terms and Conditions
                    regularly for updated terms and conditions every time before
                    using the Site. Your use of the Site after an update has
                    been made to the Terms and Conditions shall mean your
                    consent to be bound by the amended terms.
                  </p>
                </article>
              </section>
              <section class="condition-of-sale">
                <header><h3>4. USE OF THE SITE</h3></header>
                <article class="details">
                  <ol class="alphas">
                    <li>
                      You confirm that you are at least 18 years of age or are
                      accessing the Site under the supervision of a parent or
                      legal guardian.
                    </li>
                    <li>
                      We grant you a non-transferable, revocable and
                      non-exclusive license to use this Site, in accordance with
                      the Terms and Conditions of Use, for such things as:
                      shopping for personal items sold on the site, gathering
                      prior information regarding our products and services and
                      making purchases.
                    </li>
                    <li>
                      Commercial use or use on behalf of any third party is
                      prohibited, except as explicitly permitted by us in
                      advance.
                    </li>
                    <li>
                      These Terms and Conditions of Use specifically prohibit
                      actions such as: accessing our servers or internal
                      computer systems, interfering in any way with the
                      functionality of this website, gathering or altering any
                      underlying software code, infringing any intellectual
                      property rights. This list is non-exhaustive and similar
                      actions are also strictly prohibited.
                    </li>
                    <li>
                      Any breach of these Terms and Conditions of Use shall
                      result in the immediate revocation of the license granted
                      in this paragraph without prior notice to you. Should we
                      determine at our sole discretion that you are in breach of
                      any of these conditions, we reserve the right to deny you
                      access to this Site and its contents and do so without
                      prejudice to any available remedies at law or otherwise.
                    </li>
                    <li>
                      Certain services and related features that may be made
                      available on the Site may require registration or
                      subscription. You will be required to subscribe before
                      accessing those features.
                    </li>
                    <li>
                      Should you choose to register or subscribe to any of such
                      services or related features, you agree to provide
                      accurate and current information about yourself, and to
                      promptly update such information if there are any changes.
                      Every user of the Site is solely responsible for keeping
                      passwords and other account identifiers safe and secure.
                      The account owner is entirely responsible for all
                      activities that occur using his/her password or account.
                    </li>
                    <li>
                      Both parties agree that browsing the website and gathering
                      information regarding the services provided by the seller
                      does not constitute an offer to sell, but merely an
                      invitation to treat. The parties accept that an offer is
                      only made once you have selected the item you intend to
                      purchase, chosen your preferred payment method, proceeded
                      to the checkout and completed the checkout process. You
                      agree that the acceptance of the offer is not made when
                      the vendor contacts you by phone or by email to confirm
                      that the order has been placed online.
                    </li>
                    <li>
                      Your offer is only accepted when we dispatch the product
                      to you and inform you either by email or by phone of the
                      dispatch of your ordered product. Before your order is
                      confirmed, you may be asked to provide additional
                      verifications or information, including but not limited to
                      phone number and address, before we accept the order.
                      Please note that there are cases when an order cannot be
                      processed for various reasons. The Site reserves the right
                      to refuse or cancel any order for any reason at any given
                      time.
                    </li>
                    <li>
                      The Site contains ads and promotional contents, by
                      accepting to use this Site, you agree to receive
                      promotional emails from the Site. You can subsequently opt
                      out of receiving such promotional e-mails by clicking on
                      the link at the bottom of any promotional email.
                    </li>
                    <li>
                      The platform facilitates credit for the procurement of
                      renewable energy assets; an offer letter will be provided
                      when this credit process is completed. Please read
                      carefully and accept the terms of the Offer to enable you
                      to qualify for the facility.
                    </li>
                  </ol>
                  <!-- <table>
                    <tr>
                      <th class="text-center">Destination</th>
                      <th class="text-center">Transit Time</th>
                    </tr>
                    <tr>
                      <td class="text-center">Within lagos</td>
                      <td class="text-center">Next day</td>
                    </tr>
                    <tr>
                      <td class="text-center">Outside lagos</td>
                      <td class="text-center">2 - 5 Working days</td>
                    </tr>
                  </table> -->
                  <!-- <p>
                    Upon receipt and certifying of goods purchased from “us” you
                    are obliged to fill a delivery form which states goods were
                    received in good condition.
                  </p> -->
                  <!-- <div id="return-policy">
                    <h4>6. RETURN POLICY</h4>
                    Our Return Policy is as contained in the document titled
                    “Return Policy” on our Site. <br />
                  </div> -->
                  <!-- <div class="return-policies">
                    <strong>•</strong
                    ><span class="policy"
                      >For orders that support delivery, customers can cancel
                      within 1 hour after placing the order </span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >For orders that must be installed by the partner,
                      customers are allowed to cancel with 3 hours after placing
                      the order </span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >Customers can cancel due to the following reasons: </span
                    ><br />
                    <div class="bullet-points">
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Product is being delivered to a wrong address
                        (Customer’s mistake)</span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Customer/receiver is not going to be available in town
                        due to some urgent travel</span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point">Change in delivery address </span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Product is not required anymore.</span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Cheaper alternative available for lesser price and the
                        customer wants to get it at the lesser price.</span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Bad review from friends/relatives after ordering the
                        product.</span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Customer discovered the same product on another website
                        or a shop at a lower price than the order price</span
                      ><br />
                      <span class="mysp"> •</span
                      ><span class="point"
                        >Customer changes his mind and opts for another brand
                        instead</span
                      >
                    </div>
                    <br /><strong>•</strong
                    ><span class="policy"
                      >If the order is cancelled due to lower price within the
                      site, the customer is refunded the balance</span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >Customers will be given the opportunity to change their
                      address for orders cancelled due to unavailability</span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >Orders that were received in damaged conditions should be
                      reported and the orders would be replaced with a new
                      solution</span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >Any returned solution that was damaged through customers
                      misuse would not refunded</span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >The customer, partner and admin would be notified via
                      email on all successful cancellation</span
                    ><br /><strong>•</strong
                    ><span class="policy"
                      >After cancellation is approved the customer is refunded
                      with 14 working days into their account while they get
                      refunded within 5 working days if the customer opts for
                      his wallet to be credited</span
                    >
                  </div> -->
                  <!-- <h4>7. INDEMNITY</h4>
                  <p>
                    You agree to indemnify us, our affiliates, officers,
                    directors, agents and/or employees, as the case may be, free
                    from any claim or demand, including reasonable legal fees,
                    related to your breach of these Terms and Conditions of
                    Sale.
                  </p>
                  <h4>8. APPLICABLE LAW AND JURISDICTION</h4>
                  <p>
                    These Terms and Conditions of Use shall be interpreted and
                    governed by the laws in force in the Federal Republic of
                    Nigeria. Subject to the Arbitration section below, each
                    party hereby agrees to submit to the jurisdiction of the
                    courts of Nigeria and to waive any objections based upon
                    venue.
                  </p>
                  <h4>9. ARBITRATION</h4>
                  <p>
                    Any controversy, claim or dispute arising out of or relating
                    to these Terms and Conditions of Use will be referred to and
                    finally settled by private and confidential binding
                    arbitration before a single arbitrator held in Nigeria in
                    English and governed by Nigeria law pursuant to the
                    Arbitration and Conciliation Act Cap A18 Laws of the
                    Federation of Nigeria 2004, as amended, replaced or
                    re-enacted from time to time.
                  </p>
                  <p>
                    The arbitrator shall be a person who is legally trained and
                    who has experience in the information technology field in
                    Nigeria and is independent of either party. Notwithstanding
                    the foregoing, the Site reserves the right to pursue the
                    protection of intellectual property rights and confidential
                    information through injunctive or other equitable relief
                    through the courts.
                  </p>
                  <h4>10. TERMINATION</h4>
                  <p>
                    In addition to any other legal or equitable remedies, we
                    may, without prior notice to you, immediately terminate the
                    Terms and Conditions of Use or revoke any or all of your
                    rights granted under the Terms and Conditions of Use.
                  </p>
                  <p>
                    Upon any termination of this Agreement, you shall
                    immediately cease all access to and use of the Site and we
                    shall, in addition to any other legal or equitable remedies,
                    immediately revoke all password(s) and account
                    identification issued to you and deny your access to and use
                    of this Site in whole or in part. Any termination of this
                    agreement shall not affect the respective rights and
                    obligations (including without limitation, payment
                    obligations) of the parties arising before the date of
                    termination. You furthermore agree that the owner of the
                    Site shall not be liable to you or to any other person as a
                    result of any such suspension or termination.
                  </p>
                  <p>
                    If you are dissatisfied with the Site or with any terms,
                    conditions, rules, policies, guidelines, or practices of
                    operating the Site, your sole and exclusive remedy is to
                    discontinue using the Site.
                  </p>
                  <h4>11. SEVERABILITY</h4>
                  <p>
                    If any portion of these terms or conditions is held by any
                    court or tribunal to be invalid or unenforceable, either in
                    whole or in part, then that part shall be severed from these
                    Terms and Conditions of Use and shall not affect the
                    validity or enforceability of any other section listed in
                    this document.
                  </p>
                  <h4>12. MISCELLANEOUS PROVISIONS</h4>
                  <p>
                    You agree that all agreements, notices, disclosures and
                    other communications that we provide to you electronically
                    satisfy any legal requirement that such communications be in
                    writing. Assigning or sub-contracting any of your rights or
                    obligations under these Terms and Conditions of Use to any
                    third party is prohibited unless agreed upon in writing by
                    the seller.
                  </p>
                  <p>
                    We reserve the right to transfer, assign or sub-contract the
                    benefit of the whole or part of any rights or obligations
                    under these Terms and Conditions of Use to any third party.
                  </p> -->
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>5. USER SUBMISSIONS</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <ol class="alphas">
                    <li>
                      Where you provide information, including but not limited
                      to, questions, reviews, comments, and suggestions
                      (collectively, "Submissions") we will be at liberty to use
                      such Submission at our discretion In addition to the
                      rights applicable to any Submission, when you post
                      comments or reviews to the Site, you also grant us the
                      right to use the name that you submit, in connection with
                      such review, comment, or other content.
                    </li>
                    <li>
                      You shall not use a false e-mail address, pass off as
                      another person or otherwise mislead us or third parties as
                      to the origin of any submissions.
                    </li>
                    <li>
                      We may, but shall not be obligated to, remove or edit any
                      submissions.
                    </li>
                    <li>
                      By completing an order or signing up, you agree to receive
                      <ol class="romans">
                        <li>
                          emails associated with finalizing your order, which
                          may contain relevant offers from third parties,
                        </li>
                        <li>
                          emails asking you to review or rate Sterling, Sterling
                          Alternate Finance and your purchase.
                        </li>
                        <li>
                          Promotional emails, SMS and push notifications from
                          us.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>6. INFORMATION AVAILABLE ON WEBSITE</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <ol class="alphas">
                    <li>
                      You accept that the information contained in this Site is
                      provided “as is” and “as available”, and it is intended
                      for information purposes only.
                    </li>
                    <li>
                      Product representations expressed on this Site are those
                      of the approved vendors and are not made by us. We shall
                      permit vendors to display their products on our site to
                      enable you view and select the products according to your
                      specification. You agree that whatever product your select
                      was done without any duress and that you have considered
                      the product to suite your purpose before ordering the
                      goods.
                    </li>
                    <li>
                      Submissions or opinions expressed on this Site are those
                      of the individual posting such content and may not reflect
                      our opinions.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>7. ACCESSIBILITY OF WEBSITE</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    Our aim is always to ensure accessibility to the Site;
                    however, we make no representation of that nature and
                    reserve the right to terminate the operation of the website
                    at any time and without notice. You accept that service
                    interruption may occur to allow for website improvements,
                    scheduled maintenance or may also be due to factors beyond
                    our control, including but not limited to network operator
                    glitch.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>8. LINKS AND THIRD-PARTY WEBSITES</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    We may include links to third party websites on the Site
                    from time to time. However, the existence of a link to
                    another website should not be considered as an affiliation
                    or a partnership with a third party or viewed as an
                    endorsement of a particular website unless explicitly stated
                    otherwise. We take reasonable steps to verify these third
                    party links, but we advise that you investigate and accept
                    their terms and conditions before accessing their service
                    offerings. These linked sites are not under our control and
                    we accept no responsibility for any content, including, but
                    not limited to, information, products and services,
                    available on third party websites. Creating a link to this
                    website or referencing our URL is strictly forbidden without
                    our prior written consent. Furthermore, we reserve the right
                    to revoke our consent without notice or justification.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>9. SECURITY</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    You are responsible for keeping your security information
                    secure and confidential. You agree not to disclose your
                    password or PIN to any other person and shall remain liable
                    for any unauthorized disclosure, loss or compromise of your
                    PIN or password, so you must not share these with other
                    people, even if you think they work for us.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>10. INTELLECTUAL PROPERTY</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    All intellectual property rights and database rights,
                    whether registered or unregistered, information content on
                    the Site and all the website design, including, but not
                    limited to, text, graphics, software, photos, video, music,
                    sound, and their selection and arrangement, and all software
                    compilations, underlying source code and software shall
                    remain at all times vested in us or our licensors. Use of
                    such material will only be permitted as expressly authorized
                    by us or our licensors. Any unauthorized use of the material
                    and content of this website is strictly prohibited, and you
                    agree not to, or facilitate any third party to, copy,
                    reproduce, transmit, publish, display, distribute,
                    commercially exploit or create derivative works of such
                    material and content.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>11. DATA PROTECTION</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    Any personal information you supply to us (and which we
                    collect from you or other sources) will be used by us in
                    accordance with our Privacy Policy. By using the Site, you
                    explicitly consent to us collecting and using technical
                    information about your usage and device to improve our
                    products and to provide any services to you.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>12. INDEMNITY</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    You agree to indemnify and hold us, our affiliates,
                    officers, directors, agents and/or employees free from any
                    claim or demand, including reasonable legal fees, related to
                    your breach of this Terms and Conditions.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>13. ANTI-MONEY LAUNDERING</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    You are now subject to and will at all material times in the
                    future comply with all applicable laws, rules and
                    regulations concerning the detection of financial crimes,
                    prevention of terrorism and anti-money laundering and you
                    acknowledge that any transaction we deal with on your
                    instructions will be covered by statutory and Regulatory
                    requirements relating to money laundering and combating
                    terrorist financing.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>14. FORCE MAJEURE</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    To the fullest extent permitted under applicable law, we
                    will be excused from performance under these Terms for any
                    period that we are prevented from or delayed in performing
                    any obligations pursuant to this Terms, in whole or in part,
                    as a result of a Force Majeure Event. For purposes of this
                    section, “Force Majeure Event” means an event or series of
                    events beyond our reasonable control, which includes but is
                    not limited to any of the following:
                  </p>
                  <ol class="alphas">
                    <li>
                      weather conditions or other elements of nature or acts of
                      God
                    </li>
                    <li>
                      acts of war, acts of terrorism, insurrection, riots, civil
                      disorders or rebellion
                    </li>
                    <li>quarantines or embargoes</li>
                    <li>labor strikes</li>
                    <li>
                      unauthorized access to our information technology systems
                      by third parties.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>15. DISCLAIMERS</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    To the maximum extent permitted by law, we disclaim all
                    implied warranties with regard to the App. We do not promise
                    that the information, content or materials displayed on the
                    App are accurate, sufficient or error-free. We do not
                    promise that the information on our system is, when accessed
                    by you, up-to-date or complete. The App and software are
                    provided "as is" and "as available" without warranty of any
                    kind.
                    <br />
                    In no event, under no legal or equitable theory (whether
                    tort, contract, strict liability or otherwise), shall we or
                    any of our respective employees, directors, officers, agents
                    or affiliates, be liable hereunder or otherwise for any loss
                    or damage of any kind, direct or indirect, in connection
                    with or arising from the use of the Site / services or our
                    agreement with you concerning the services, including, but
                    not limited to
                  </p>
                  <ol class="romans">
                    <li>
                      the use of or inability to use the Site, the service, or
                      the content,
                    </li>
                    <li>
                      any transaction conducted through or facilitated by the
                      Site
                    </li>
                    <li>
                      any claim attributable to errors, omissions, or other
                      inaccuracies in the Site, the service and/or the content
                    </li>
                    <li>
                      unauthorized access to or alteration of your transmissions
                      or data, or
                    </li>
                    <li>
                      any other matter relating to the Site, the service, or the
                      content, and any compensatory, direct, consequential,
                      incidental, indirect, special or punitive damages, lost
                      anticipated profits, loss of goodwill, loss of data,
                      business interruption, accuracy of results, or computer
                      failure or malfunction, even if we have been advised of or
                      should have known of the possibility of such damages.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>16. ACCEPTANCE OF ELECTRONIC DOCUMENTS</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    You agree that this Terms and Conditions, other, notices,
                    disclosures and other communications that we provide to you
                    electronically satisfy any legal requirement that requires
                    such communications to be in writing.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>17. PAYMENT AND PRICING</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <ol class="alphas">
                    <li>
                      We are determined to provide the most accurate pricing
                      information on the Site to our users; however, errors may
                      occur, such as cases when the price of an item is not
                      displayed correctly on the website. As such, we reserve
                      the right to refuse or cancel any order. In the event that
                      an item is mispriced, we may, at our own discretion,
                      either contact you for instructions or cancel your order
                      and notify you of such cancellation. We shall have the
                      right to refuse or cancel any such orders whether the
                      order has been confirmed and your credit/debit card
                      charged or not.
                    </li>
                    <li>
                      In the event that we are unable to provide the services,
                      we will inform you of this as soon as possible. A full
                      refund will be given where you have already paid for the
                      products.
                    </li>
                    <li>
                      When you initiate a payment by entering the required
                      details in the Site, you explicitly give your consent and
                      authorize us to make the payment on the details you have
                      provided. It is your responsibility to check that all
                      details are accurate; we will not be liable where wrong
                      details are provided by you. If you are initiating
                      payments related to a purchase, then the relationship for
                      that purchase remains between you and the relevant
                      supplier – We are in no way responsible for that purchase,
                      or any terms set by that supplier. If you think that a
                      payment initiated through the Site may have been
                      incorrect, unauthorized, or not properly executed (perhaps
                      due to delay or other error), then you need to: contact us
                      immediately so that we can investigate and take necessary
                      actions.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>18. DELIVERY</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    This Site is only for delivery of products to customers
                    within Nigeria. We make every effort to deliver goods within
                    the estimated timescales set out on our Site; however,
                    delays are occasionally inevitable due to unforeseen
                    factors. We shall be under no liability for any delay or
                    failure to deliver the products within the estimated
                    timeframes where they did not occur due to our fault or
                    negligence. Our Delivery Policy is as contained in the table
                    below.
                  </p>
                  <table>
                    <tr>
                      <th class="text-center">Destination</th>
                      <th class="text-center">Transit Time</th>
                    </tr>
                    <tr>
                      <td class="text-center">Within lagos</td>
                      <td class="text-center">Next day</td>
                    </tr>
                    <tr>
                      <td class="text-center">Outside lagos</td>
                      <td class="text-center">2 - 5 Working days</td>
                    </tr>
                  </table>
                  <p>
                    Upon receipt and certifying of goods purchased from “us” you
                    are obliged to fill a delivery form which states goods were
                    received in good condition.
                  </p>
                </article>
              </section>
              <section class="use-of-site" id="return-policy">
                <header><h3>19. RETURN POLICY</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>Our Return Policy is:</p>
                  <ol class="alphas">
                    <li>
                      For orders that support delivery, customers can cancel
                      within 1 hour after placing the order
                    </li>
                    <li>
                      For orders that must be installed by the partner,
                      customers are allowed to cancel with 3 hours after placing
                      the order. After 3 hours, cancelation of an order is at
                      our discretion.
                    </li>
                    <li>
                      Customers will be given the opportunity to change their
                      address for orders cancelled because the recipient is not
                      at the address at the time
                    </li>
                    <li>
                      Orders that were received in damaged conditions should be
                      reported and the orders would be replaced with a new
                      solution
                    </li>
                    <li>
                      Any returned solution that was damaged through customers
                      misuse would not be refunded.
                    </li>
                    <li>
                      The customer, partner and admin would be notified via
                      email on all successful cancellation.
                    </li>
                    <li>
                      After cancellation is approved, the customer’s refund is
                      treated within 14 working days.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header><h3>20. CONTACT US</h3></header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    You can contact us via email on
                    <a href="mailto:info@imperiumng.com">info@imperiumng.com</a>
                    and phone
                    <a href="tel:+234 (081) 6413 3937">+234 (081) 6413 3937</a>
                    and
                    <a href="tel:+234 (081) 3547 3439">+234 (081) 3547 3439</a>.
                  </p>
                  <p>
                    We will contact you in English language and via this Site,
                    through your email, phone number or home address.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header>
                  <h3>21. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
                </header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <ol class="alphas">
                    <li>
                      These Terms are governed by the laws of the Federal
                      Republic of Nigeria.
                    </li>
                    <li>
                      We will use our best efforts to resolve any issue that may
                      arise from the use of the Site. However, we realize that
                      there may be rare cases where we may not be able to
                      resolve an issue to your satisfaction. In the event we
                      cannot resolve a dispute between us, you agree that all
                      matters related to any use or access to the Site or the
                      services shall be referred to Mediation at the Lagos
                      Multi-Door Courthouse, in accordance with the Lagos State
                      Multidoor Courthouse Rules 2007 or extant law.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header>
                  <h3>22. TERMINATION</h3>
                </header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <ol class="alphas">
                    <li>
                      In addition to any other legal or equitable remedies, we
                      may, without prior notice to you, immediately terminate
                      the Terms and Conditions of Use or revoke any or all of
                      your rights granted under the Terms and Conditions of Use.
                    </li>
                    <li>
                      Upon any termination of this Agreement, you shall
                      immediately cease all access to and use of the Site and we
                      shall, in addition to any other legal or equitable
                      remedies, immediately revoke all password(s) and account
                      identification issued to you and deny your access to and
                      use of this Site in whole or in part.
                    </li>
                    <li>
                      Any termination of this agreement shall not affect the
                      respective rights and obligations (including without
                      limitation, payment obligations) of the parties arising
                      before the date of termination. You furthermore agree that
                      the owner of the Site shall not be liable to you or to any
                      other person as a result of any such suspension or
                      termination.
                    </li>
                    <li>
                      If you are dissatisfied with the Site or with any terms,
                      conditions, rules, policies, guidelines, or practices of
                      operating the Site, your sole and exclusive remedy is to
                      discontinue using the Site.
                    </li>
                  </ol>
                </article>
              </section>
              <section class="use-of-site">
                <header>
                  <h3>23. SEVERABILITY</h3>
                </header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    If any portion of these terms or conditions is held by any
                    court or tribunal to be invalid or unenforceable, either in
                    whole or in part, then that part shall be severed from these
                    Terms and Conditions of Use and shall not affect the
                    validity or enforceability of any other section listed in
                    this document.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header>
                  <h3>24. ASSIGNMENT</h3>
                </header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    Assigning or sub-contracting any of your rights or
                    obligations under these Terms and Conditions of Use to any
                    third party is prohibited unless agreed upon in writing by
                    the seller. We reserve the right to transfer, assign or
                    sub-contract the benefit of the whole or part of any rights
                    or obligations under these Terms and Conditions of Use to
                    any third party.
                  </p>
                </article>
              </section>
              <section class="use-of-site">
                <header>
                  <h3>25. REPRESENTATIONS/WARRANTIES</h3>
                </header>
                <article class="details">
                  <!-- <h4>1. USE OF THE SITE</h4> -->
                  <p>
                    If you register to use the Site, then you represent and
                    warrant that:
                  </p>
                  <ol class="alphas">
                    <li>
                      you will not use, or misuse, the Site in any way which may
                      impair its functionality;
                    </li>
                    <li>
                      you will not use another person’s email address, identity
                      or contact detail;
                    </li>
                    <li>
                      you will not copy, modify, translate, or otherwise create
                      derivative works from any part of, or reverse engineer any
                      part of the Site (or attempt to do so, or assist anyone
                      else to do so);
                    </li>
                    <li>
                      you will not sell, transfer, or assign any rights you have
                      in relation to the Site – this includes sharing your
                      access or information from the Site with any other person
                    </li>
                    <li>
                      you are solely responsible for all activities that occur
                      using your password and login whether or not you authorize
                      the activity;
                    </li>
                    <li>
                      you will not interfere with, or disrupt, the provision of
                      the Site or its use by anyone else;
                    </li>
                    <li>
                      your use of the Site does not contravene the laws of the
                      Federal Republic of Nigeria;
                    </li>
                  </ol>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    if (this.$route.hash) {
      const el = document.querySelector(this.$route.hash);
      // console.log(el);
      setTimeout(() => {
        el.scrollIntoView();
      }, 100);
    }
  },
};
</script>
<style lang="scss">
#return-policy {
  padding-top: 30px;
}
#terms-and-conditions {
  color: black;
  h3 {
    color: #219653;
    opacity: 0.8;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  h4 {
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
#terms-and-conditions .details table {
  width: 80%;
  font-size: 1.1rem;
  border-collapse: collapse;
  margin: 0 auto 2rem;
  th {
    font-weight: 600;
  }
}
#terms-and-conditions .details table td,
#terms-and-conditions .details table th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
  padding-left: 2rem;
}
article.details p {
  line-height: 30px;
  margin-bottom: 40px;
}
.return-policies .bullet-points {
  padding-left: 30px;
}
.return-policies strong,
.mysp {
  margin-right: 10px;
  display: inline-block;
}
</style>